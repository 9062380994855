<!--<div class="background-container">-->
<!--  &lt;!&ndash;    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/moon2.png" alt="">&ndash;&gt;-->
<!--  <div class="stars"></div>-->
<!--  <div class="twinkling"></div>-->
<!--  <app-root></app-root>-->
<!--  &lt;!&ndash;    <div class="clouds"></div>&ndash;&gt;-->
<!--</div>-->


<ng-template appPlaceholder></ng-template>
<div *ngIf="isLoading" style="text-align: center">
  <app-loading-spinner></app-loading-spinner>
</div>
<div class="container" *ngIf="!isLoading">
  <!--<div class="container">-->
  <div class="card card-container">
    <img src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" class="profile-img-card"/>
    <form name="form-signin" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
      <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
        {{errorMessage}}
      </div>
      <div class="form-group" [ngClass]="{'has-error': f.submitted && !username.valid}">
        <label for="username">Username</label>
        <input type="text" name="username" class="form-control" id="username" [(ngModel)]="user.username" #username="ngModel" required/>
        <div class="alert alert-danger" *ngIf="f.submitted && !username.valid">
          Username is required.
        </div>
      </div>
      <div class="form-group" [ngClass]="{'has-error': f.submitted && !password.valid}">
        <label for="password">Password</label>
        <input type="password" name="password" class="form-control" id="password" [(ngModel)]="user.password" #password="ngModel" required/>
        <div class="alert alert-danger" *ngIf="f.submitted && !password.valid">
          Password is required.
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-block">Sign In</button>
    </form>
    <a routerLink="/register" class="btn btn-link">Create New Account!</a>
    <a routerLink="/remember" class="btn btn-link">I forgot my password!</a>
  </div>
</div>
