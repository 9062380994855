<footer class="main-footer">
  <nav>
    <!-- <ul class="main-footer__links">
      <li class="main-footer__link">
        <a href="#">Crated in Wrocław</a><br>
        <a href="#">Building with <span class="material-icons">favorite</span> in Toronto</a>
      </li>
      <li class="main-footer__link">
        <a href="#">Release in Q2 2023</a>
      </li>
      <li class="main-footer__link">
        <a href="#">Contact us: support@javalx.com</a>
      </li>
    </ul> -->
    <ul class="main-footer__links">
      <li class="main-footer__link">
        <label href="#">Java</label> | <label href="#">Kafka</label> | <label href="#">JavaScript</label> | <label href="#">Angular</label> | <label href="#">React</label>
      </li>
      <li class="main-footer__link">
        <a href="#">Contact us: support@javalx.com</a>
      </li>
    </ul>
  </nav>
</footer>
