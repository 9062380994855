import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) {

  }

  ngOnInit(): void {
  }

  // ngOnInit(): void {
  //   this.metaService.addTags([
  //     {name: 'keywords', content: 'Java, JavaLx, LX, Java Lx, Java Education, Java Courses, Java Education,' +
  //         'Java Development, Spring Framework, Learn Java, Learn Java Simple, Learn Java Fast, Learn Spring, ' +
  //         'JavaEE'},
  //     {name: 'description', content: 'JavaLx is the practical Java course. ' +
  //         'Our mission is provide the best education in the Java development. ' +
  //         'We believe that anyone can become a programmer and do our best to make it simple and easy as possible!'},
  //     {name: 'robots', content: 'index, follow, archive, snippet'}
  //   ]);
  // }

}
