<!--<p>about-us works!</p>-->
<div class="center">
  <h3>JavaLx is a leading provider of IT outsourcing and consulting services, specializing in both legacy and modern codebases.</h3>
  <h4>Our commitment to delivering innovative solutions, combined with our deep understanding of the industry, 
    has made us a trusted partner for businesses seeking to streamline their technology operations.</h4>
  <hr>
  <h4>We provide full-stuck development and consulting</h4>
  <h4>Our experience with <strong>Java, JavaEE, Spring, Spring Boot, Spring Cloud, JavaScript and Node.js</strong></h4>
  <h4>Working with different databases as <strong>Oracle, Postgresql, MySQL, Cassandra, MongoDB</strong></h4>
  <h4>Frontend with <strong>Angular or React</strong></h4>
  <hr>
  <!-- <h4 style="text-align: center;">Our clients</h4> -->
  <div class="images">
    <img src="assets/img/a1.png" class="img-logo">
    <img src="assets/img/a2.png" class="img-logo">
    <img src="assets/img/a3.png" class="img-logo">
    <img src="assets/img/a4.png" class="img-logo">
    <img src="assets/img/a6.png" class="img-logo">
    <img src="assets/img/a7.png" class="img-logo">
    <img src="assets/img/a8.png" class="img-logo">
  </div>
  <br>
  <h5 class="email">Questions? support@javalx.com</h5>
</div>

<!-- Javalx provides outsourcing and consulting services for IT. 
Our specialization for Java, Kafka, JavaScript, Angular, React. 
We are having experience with legacy and modern code bases and help our clients upgrade technology to new levels. -->
<!-- <div class="center">
  <h3>JavaLx is the practical Java course.</h3>
  <h4>We adding 400+ short-lectures from 1 to 10 minutes, because we don't want overload our students</h4><br>
  <h4>We adding 1000+ tasks (each of them has different level of difficulties),
    because we believe that the best way to become a programmer only through practice</h4><br>
  <h4>We made lifetime compiler with embedded validator which
    can scan your code and say what wrong with it, so you are able to fix</h4>
  <br>
  <hr>
  <br>
  <h4>Our mission is provide the best education in the Java development</h4>
  <h4>We believe that anyone can become a programmer
    and do our best to make it simple and easy as possible!</h4>
  <h4>We believe that anyone can become a programmer
    and we adding entry points for people with different level of preparation</h4>
  <h4>From a person who doesn't know anything about IT to a full-stack developer.</h4>
  <h4>From someone who knows the basics of programming to an experienced and competitive programmer.</h4>
  <hr>
  <h5>Sections</h5>
  <h5>Java Core, Java Multithreading, Java 8, Java EE</h5>
  <h5>Spring Framework, Hibernate, Maven, Patterns</h5>
  <br>
  <br>
  <h5>Questions? support@javalx.com</h5>
</div> -->
