
<nav class="navbar navbar-inverse navbar-expand-lg navbar-fixed-top sticky-top">
  <div class="container">

    <a routerLink="/" class="navbar-brand">
      Java LX
    </a>

    <div class="navbar-nav ml-auto" >
      <!-- <li class="nav-item">
        <a routerLink="/how-it-works" class="nav-link">
          How it works?
        </a>
      </li>
      <li class="nav-item">
        <a routerLink="/courses" class="nav-link">
          Courses
        </a>
      </li> -->

      <li class="nav-item">
        <a routerLink="/services" class="nav-link">
          Services
        </a>
      </li>
      <li class="nav-item">
        <a routerLink="/about-us" class="nav-link">
          About us
        </a>
      </li>
      <!-- <li class="nav-item">
        <a routerLink="/careers" class="nav-link">
          Careers
        </a>
      </li> -->

    </div>
  </div>
</nav>
<!--<app-welcome></app-welcome>-->
<main>
<router-outlet></router-outlet>
</main>
<app-footer></app-footer>

