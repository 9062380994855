import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {RegisterComponent} from './components/register/register.component';
import {UserAccountComponent} from './components/user-account/user-account.component';
// import {Lecture2Component} from './components/level1/lecture2/lecture2.component';
// import {Lecture1Component} from './components/level1/lecture1/lecture1.component';

// import {Lecture1Component} from './components/level2/lecture1/lecture1.component';

import {AboutUsComponent} from './components/about-us/about-us.component';
import {WelcomeComponent} from './welcome/welcome.component';

import { AllLevelsComponent} from './components/course-main/all-levels/all-levels.component';
import {JavaBasicComponent} from './components/course-main/all-levels/java-basic/java-basic.component';
import {JavaCoreComponent} from './components/course-main/all-levels/java-core/java-core.component';
import {JavaCollectionsComponent} from './components/course-main/all-levels/java-collections/java-collections.component';
import {JavaMultithreadingComponent} from './components/course-main/all-levels/java-multithreading/java-multithreading.component';
import {JavaAdditionComponent} from './components/course-main/all-levels/java-addition/java-addition.component';
import {JavaEnterpriseComponent} from './components/course-main/all-levels/java-enterprise/java-enterprise.component';
import {JavaSpringComponent} from './components/course-main/all-levels/java-spring/java-spring.component';
import {Level1Component} from './components/course-main/level1/level1.component';

import {Lecture1Component} from './components/course-main/level1/lecture1/lecture1.component';
import {Lecture2Component} from './components/course-main/level1/lecture2/lecture2.component';
import {Lecture3Component} from './components/course-main/level1/lecture3/lecture3.component';
import {Lecture4Component} from './components/course-main/level1/lecture4/lecture4.component';
import {Lecture5Component} from './components/course-main/level1/lecture5/lecture5.component';
import {Lecture6Component} from './components/course-main/level1/lecture6/lecture6.component';
import {Lecture7Component} from './components/course-main/level1/lecture7/lecture7.component';
import {Lecture8Component} from './components/course-main/level1/lecture8/lecture8.component';
import {Lecture9Component} from './components/course-main/level1/lecture9/lecture9.component';
import {Lecture10Component} from './components/course-main/level1/lecture10/lecture10.component';

import {Lecture11Component as Level2Lecture1Component} from './components/course-main/level2/lecture1/lecture11.component';
import {Lecture12Component as Level2Lecture2Component} from './components/course-main/level2/lecture2/lecture12.component';
import {Lecture13Component as Level2Lecture3Component} from './components/course-main/level2/lecture3/lecture13.component';
import {Lecture14Component as Level2Lecture4Component} from './components/course-main/level2/lecture4/lecture14.component';
import {Lecture15Component as Level2Lecture5Component} from './components/course-main/level2/lecture5/lecture15.component';
import {Lecture16Component as Level2Lecture6Component} from './components/course-main/level2/lecture6/lecture16.component';
import {Lecture17Component as Level2Lecture7Component} from './components/course-main/level2/lecture7/lecture17.component';
import {Lecture18Component as Level2Lecture8Component} from './components/course-main/level2/lecture8/lecture18.component';
import {Lecture19Component as Level2Lecture9Component} from './components/course-main/level2/lecture9/lecture19.component';
import {Lecture20Component as Level2Lecture10Component} from './components/course-main/level2/lecture10/lecture20.component';
import {HowItWorksComponent} from './components/how-it-works/how-it-works.component';
import {CareersComponent} from './components/careers/careers.component';
import {Job14Component} from './components/careers/job14/job14.component';
import {Job17Component} from './components/careers/job17/job17.component';


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'user-account', component: UserAccountComponent},
  // {path: 'next', component: UserAccountComponent},
  // {path: 'next/:level1/:sublevel1', component: Lecture1Component},
  // {path: 'next/:level1/:sublevel2', component: Lecture2Component},
  // {path: 'next/:level1/:sublevel3', component: Lecture3Component},
  // {path: 'next/:level2/:sublevel1', component: Lecture1Component},

  // {path: 'course/level1/sublevel1', component: Lecture1Component},
  // {path: 'course/level1/sublevel2', component: Lecture2Component},
  // {path: 'course/level1/sublevel3', component: Lecture3Component},
  // {path: 'course/level1/sublevel4', component: Lecture4Component},




  {path: 'about-us', component: AboutUsComponent},

  // {path: 'careers', component: CareersComponent},
  // {path: 'careers/jobs/14', component: Job14Component},
  // {path: 'careers/jobs/17', component: Job17Component},

  // {path: 'how-it-works', component: HowItWorksComponent},


  // {path: 'courses', component: AllLevelsComponent}, // OLD
  {path: 'services', component: JavaMultithreadingComponent}, // NEW

  // {path: 'courses/essential', component: JavaBasicComponent},
  // ADD TO SITE MAP LATER
  // {path: 'courses/core', component: JavaCoreComponent},
  // {path: 'courses/collections', component: JavaCollectionsComponent},
  // {path: 'courses/multithreading', component: JavaMultithreadingComponent},
  // {path: 'courses/addition', component: JavaAdditionComponent},
  // {path: 'courses/enterprise', component: JavaEnterpriseComponent},
  // {path: 'courses/spring', component: JavaSpringComponent},



  // {path: 'courses/essential/level1', component: Level1Component},

  // {path: 'courses/essential/level1/lecture1', component: Lecture1Component},
  // {path: 'courses/essential/level1/lecture2', component: Lecture2Component},
  // {path: 'courses/essential/level1/lecture3', component: Lecture3Component},
  // {path: 'courses/essential/level1/lecture4', component: Lecture4Component},
  // {path: 'courses/essential/level1/lecture5', component: Lecture5Component},
  // {path: 'courses/essential/level1/lecture6', component: Lecture6Component},
  // {path: 'courses/essential/level1/lecture7', component: Lecture7Component},
  // {path: 'courses/essential/level1/lecture8', component: Lecture8Component},
  // {path: 'courses/essential/level1/lecture9', component: Lecture9Component},
  // {path: 'courses/essential/level1/lecture10', component: Lecture10Component},


  // {path: 'courses/essential/level2/lecture1', component: Level2Lecture1Component},
  // {path: 'courses/essential/level2/lecture2', component: Level2Lecture2Component},
  // {path: 'courses/essential/level2/lecture3', component: Level2Lecture3Component},
  // {path: 'courses/essential/level2/lecture4', component: Level2Lecture4Component},
  // {path: 'courses/essential/level2/lecture5', component: Level2Lecture5Component},
  // {path: 'courses/essential/level2/lecture6', component: Level2Lecture6Component},
  // {path: 'courses/essential/level2/lecture7', component: Level2Lecture7Component},
  // {path: 'courses/essential/level2/lecture8', component: Level2Lecture8Component},
  // {path: 'courses/essential/level2/lecture9', component: Level2Lecture9Component},
  // {path: 'courses/essential/level2/lecture10', component: Level2Lecture10Component},



  // {path: 'next/level1/sublevel3', component: Lecture3Component},
  // {path: 'next/level2/sublevel1', component: Lecture1Component},

  // for not existing path
  // { path: '**', component: WelcomeComponent }
  { path: '**', component: AboutUsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
