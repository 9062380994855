<br>
<div class="cards-service">
    <div class="card-service">
        <div class="card-header">Accessablility</div>
        <div class="card-body">
            We deliver accessibility solutions for devices and web sites to ensure that everyone, including individuals with disabilities, 
            can access and use technology. <br/>

            The goal of accessibility is to create an inclusive digital environment where everyone can access and use technology, 
            regardless of their abilities or disabilities. This includes web content and applications, as well as digital devices such as smartphones, 
            tablets, and computers. <br/>

            To achieve accessibility, web and device developers must follow guidelines and standards, such as the Web Content Accessibility 
            Guidelines (WCAG), and conduct thorough testing to ensure that their products are usable by people with disabilities. 
            This can involve implementing accessibility features, such as alternative text for images, keyboard navigation, and 
            accessible forms, as well as integrating assistive technologies, such as screen readers.

        </div>
    </div>
    <div class="card-service">
        <div class="card-header">Application Services & Modernization </div>
        <div class="card-body">We deliver application services and modernization to help organizations transform their 
            technology infrastructure and take advantage of the benefits of modern technology. <br/>

            <ul style="text-align: left;">
                <li>Assessment of existing applications and systems to identify areas for improvement.</li>
                <li>Modernization of legacy systems and applications to take advantage of new technologies and to meet the demands of a changing business environment.</li>
                <li>Development of new applications and systems to support the evolving needs of the organization.</li>
              </ul>
            
            By delivering application services and modernization, we help organizations transform their technology 
            infrastructure and take advantage of the benefits of modern technology, including increased efficiency, 
            improved performance, and better alignment with changing business needs. Our team of experts is dedicated to helping 
            organizations succeed with their technology initiatives and achieve their business goals.
        </div>
    </div>
    <div class="card-service">
        <div class="card-header">Cloud Solutions</div>
        <div class="card-body">
            Our cloud solutions are designed to meet the unique needs of each organization, and we offer a range of services 
            to support the adoption and implementation of cloud technology. <br/>
            These services include: <br/> 
            <ul style="text-align: left;">
                <li>Assessment of existing technology infrastructure to identify opportunities for cloud migration.</li>
                <li>Design and implementation of cloud infrastructure, including the selection of appropriate cloud platforms and technologies.</li>
                <li>Migration of existing applications and data to the cloud to reduce costs and improve scalability.</li>
                <li>Integration of cloud solutions with existing systems to increase efficiency and collaboration.</li>
                <li>Ongoing maintenance and support to ensure that cloud infrastructure remains secure and up-to-date.</li>
              </ul>


By delivering cloud solutions, we help organizations transform their technology infrastructure and take 
advantage of the benefits of cloud computing, including increased scalability, reduced costs, and improved efficiency and collaboration.

        </div>
    </div>
    <div class="card-service">
        <div class="card-header">Core Modernization</div>
        <div class="card-body">
            Core modernization is an important part of modernizing an organization's technology infrastructure. 
            It involves updating and transforming legacy systems and applications to take advantage of new technologies 
            and to meet the demands of a changing business environment. <br/> 

            <ul style="text-align: left;">
                <li>Design and implementation of modern architecture and technology solutions, including the selection of appropriate platforms and tools.</li>
                <li>Migration of existing data and applications to new systems to improve performance and efficiency.</li>
                <li>Integration of modern systems and applications with existing technology infrastructure to increase efficiency and collaboration.</li>
                <li>Ongoing maintenance and support to ensure that modern systems remain secure and up-to-date.</li>
              </ul>
            

By delivering core modernization services, we help organizations transform their technology infrastructure and take 
advantage of the benefits of modern systems and applications, including increased efficiency, 
improved performance, and better alignment with changing business needs.

        </div>
    </div>
    <div class="card-service">
        <div class="card-header">Digital experience</div>
        <div class="card-body">
            We deliver digital experiences to help organizations engage with customers and stakeholders in new and meaningful ways. <br/> 

Our digital experience services are designed to meet the unique needs of each organization, and we offer a range of services to 
support the development and implementation of digital experiences. <br/> 

By delivering digital experiences, we help organizations engage with customers and stakeholders in new and meaningful ways, 
providing a more personalized and interactive experience and helping to build stronger relationships.

        </div>
    </div>
    <div class="card-service">
        <div class="card-header">Security</div>
        <div class="card-body">
            Security is a critical aspect of modern technology and we are committed to delivering secure solutions to our clients. <br/> 

            By delivering secure solutions, we help organizations protect their data, systems, and customers from threats and reduce
             the risk of security incidents. Our security services are designed 
            to provide peace of mind and help organizations meet their regulatory and compliance requirements. <br/> 

        </div>
    </div>
    <div class="card-service">
        <div class="card-header">Software Product Engineering</div>
        <div class="card-body">
            We deliver software product engineering services to help organizations bring innovative products to market. <br/> 
            By delivering software product engineering services, we help organizations bring innovative products to market, 
            increase their competitiveness, and achieve their business goals. Our team of experienced software engineers and product 
            experts is dedicated to helping 
            organizations succeed with their product development initiatives. <br/> 
        </div>
    </div>
</div>








