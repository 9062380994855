import {Role} from './role';

export class User {

  id: number;
  // id: number;
  username: string = '';
  password: string = '';
  key: string = '';
  displayName: string = '';
  level: string = '';
  blackMatter: string = '';
  country: string = '';
  city: string = '';
  email: string = '';

  enabled: boolean;
  role: Role;

}
