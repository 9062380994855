<p>user-account works!</p>
<form (ngSubmit)="!todoForm.invalid" #todoForm="ngForm">
  <fieldset class="form-group">
    <label>Username</label>
    <input type="text" #username="ngModel" [(ngModel)]="currentUser.username" class="form-control"  name="username">
  </fieldset>
  <fieldset class="form-group">
    <label>Key</label>
    <input type="text" #key="ngModel" [(ngModel)]="currentUser.key" class="form-control"  name="key">
  </fieldset>
  <fieldset class="form-group">
    <label>Black Matter</label>
    <input type="text" #blackMatter="ngModel" [(ngModel)]="currentUser.blackMatter" class="form-control"  name="blackMatter" readonly>
  </fieldset>
  <fieldset class="form-group">
    <label>email</label>
    <input type="text" #email="ngModel" [(ngModel)]="currentUser.email" class="form-control"  name="email">
  </fieldset>
  <button type="submit" class="btn btn-success" disabled>Update</button>
</form>

<button mat-raised-button (click)="nextLevel()" class="btn btn-success">
  Next
</button>

<!-- sm-6 -->
