import {Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from '../models/user';
import {Role} from '../models/role';
import {TaskUserSolution} from '../models/task-user-solution/task-user-solution';
import {TaskFileInto} from '../models/task-user-solution/task-file-into';

// const API_URL = 'http://localhost:8888/api/1.0/rest/tasks/';
const API_URL = 'http://localhost:8080/api/1.0/rest/tasks/';
const API_URL2 = 'http://localhost:8081/api/1.0/rest/tasks/';

// const API_URL = 'https://server.javalx.com/api/1.0/rest/tasks/';
// const API_URL2 = 'https://server.javalx.com/api/1.0/rest/tasks/';

// const API_URL = 'http://35.222.92.117:8080/api/1.0/rest/tasks/';
// const API_URL2 = 'http://34.123.139.17:8081/api/1.0/rest/tasks/';
@Injectable({
  providedIn: 'root'
})
export class TaskService {

  public currentUser: Observable<User>;
  currentUserSubject: BehaviorSubject<User>;
  userAdmin: BehaviorSubject<User>;
  roles: Array<Role>;
  private userList: Array<User>;
  userChanged = new Subject<Array<User>>();



  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser'))); // get current user from JSON
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  nextLevel(userId: number, price: number, level: number, lecture: number): Observable<any> {
    return this.http.post(API_URL + 'next?userId=' + userId + '&price=' + price + '&level=' + level + '&lecture=' + lecture,
      JSON.stringify(userId),
      {headers: {'Content-Type': 'application/json; charset=UTF-8'}});
  }

  // public async postCodeToAnalysis(questionId: string, code: string) {
  //   const queryUrl = API_URL + 'task/com/example/task/task1/validations';
  //   // new TaskFileInto('com/example/task/task1/HelloWord.java', code);
  //   // const requestBody = {
  //   //   taskFIleInfo: [new TaskFileInto('com/example/task/task1/HelloWord.java', code)],
  //   //   inputData: null,
  //   //   inputData2: null,
  //   // };
  //   this.taskUserSolutionModule = new TaskUserSolutionModule(new TaskFileInto('com/example/task/task1/HelloWord.java', code), null, null);
  //   try {
  //     // const res = await this.http.post(queryUrl, requestBody, { observe: 'response' }).toPromise();
  //     const res = await this.http.post(queryUrl, this.taskUserSolutionModule, { observe: 'response' }).toPromise();
  //     console.log(res);
  //     return res;
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   }
  //
  // }
  public postCodeToAnalysis(taskUserSolutionModule: TaskUserSolution): Observable<any> {
    const taskPath = taskUserSolutionModule.files[0].path.substr(0, taskUserSolutionModule.files[0].path.lastIndexOf('/'));
    // const queryUrl = API_URL + 'task/com/example/task/task1/validations';
    const queryUrl = API_URL + `task/${taskPath}/validations`; // flexible path
    // console.log('oke ' + queryUrl);

    return this.http.post(queryUrl, JSON.stringify(taskUserSolutionModule),
      {headers: {'Content-Type': 'application/json; charset=UTF-8'}});
  }

  public getCodeFromServer(taskId: number): Observable<any> {
    const queryUrl = API_URL2 + 'service/find/task/' + taskId;
    return this.http.get(queryUrl,
      {headers: {'Content-Type': 'application/json; charset=UTF-8'}});
  }
  public getTaskDetailsFromServer(taskId: number): Observable<any> {
    const queryUrl = API_URL2 + 'service/find/task/details/' + taskId;
    return this.http.get(queryUrl,
      {headers: {'Content-Type': 'application/json; charset=UTF-8'}});
  }
}
