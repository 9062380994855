import { BrowserModule } from '@angular/platform-browser';
import {Inject, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { UserAccountComponent } from './components/user-account/user-account.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { WelcomeComponent } from './welcome/welcome.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { Lecture4Component } from './components/course-main/level1/lecture4/lecture4.component';
import { Lecture5Component } from './components/course-main/level1/lecture5/lecture5.component';
import { Lecture6Component } from './components/course-main/level1/lecture6/lecture6.component';
import { Lecture7Component } from './components/course-main/level1/lecture7/lecture7.component';
import { Lecture8Component } from './components/course-main/level1/lecture8/lecture8.component';
import { Lecture9Component } from './components/course-main/level1/lecture9/lecture9.component';
import { Lecture10Component } from './components/course-main/level1/lecture10/lecture10.component';
import { NextLevelButtonComponent } from './essential/next-level-button/next-level-button.component';
import {Lecture1Component} from './components/course-main/level1/lecture1/lecture1.component';
import {Lecture2Component} from './components/course-main/level1/lecture2/lecture2.component';
import {Lecture3Component} from './components/course-main/level1/lecture3/lecture3.component';
import { AllLevelsComponent } from './components/course-main/all-levels/all-levels.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import {MatButtonModule} from '@angular/material/button';
import { PrevLevelButtonComponent } from './essential/prev-level-button/prev-level-button.component';
import { IdeComponent } from './essential/ide/ide.component';
import { JavaBasicComponent } from './components/course-main/all-levels/java-basic/java-basic.component';
import { JavaCoreComponent } from './components/course-main/all-levels/java-core/java-core.component';
import { JavaCollectionsComponent } from './components/course-main/all-levels/java-collections/java-collections.component';
import { JavaMultithreadingComponent } from './components/course-main/all-levels/java-multithreading/java-multithreading.component';
import { JavaAdditionComponent } from './components/course-main/all-levels/java-addition/java-addition.component';
import { JavaEnterpriseComponent } from './components/course-main/all-levels/java-enterprise/java-enterprise.component';
import { JavaSpringComponent } from './components/course-main/all-levels/java-spring/java-spring.component';
import { Level1Component } from './components/course-main/level1/level1.component';
import { FooterComponent } from './essential/footer/footer.component';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';

// import { AppServerModule } from '/src/main.server';
import { ArrowComponent } from './essential/arrow/arrow.component';
import { ArrowLeftRightComponent } from './essential/arrow-left-right/arrow-left-right.component';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import {Lecture11Component} from './components/course-main/level2/lecture1/lecture11.component';
import {Lecture12Component} from './components/course-main/level2/lecture2/lecture12.component';
import {Lecture13Component} from './components/course-main/level2/lecture3/lecture13.component';
import {Lecture14Component} from './components/course-main/level2/lecture4/lecture14.component';
import {Lecture15Component} from './components/course-main/level2/lecture5/lecture15.component';
import {Lecture16Component} from './components/course-main/level2/lecture6/lecture16.component';
import {Lecture17Component} from './components/course-main/level2/lecture7/lecture17.component';
import {Lecture18Component} from './components/course-main/level2/lecture8/lecture18.component';
import {Lecture19Component} from './components/course-main/level2/lecture9/lecture19.component';
import {Lecture20Component} from './components/course-main/level2/lecture10/lecture20.component';
import { Lecture21Component } from './components/course-main/level3/lecture21/lecture21.component';
// import { CareersComponent } from './components/careers/careers.component';
// import { Job14Component } from './components/careers/job14/job14.component';
// import { Job17Component } from './components/careers/job17/job17.component';
// import { ApplyComponent } from './components/careers/apply/apply.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    UserAccountComponent,
    LoadingSpinnerComponent,
    Lecture1Component,
    Lecture2Component,
    Lecture3Component,
    WelcomeComponent,
    AboutUsComponent,
    Lecture4Component,
    Lecture5Component,
    Lecture6Component,
    Lecture7Component,
    Lecture8Component,
    Lecture9Component,
    Lecture10Component,
    Lecture11Component,
    Lecture12Component,
    Lecture13Component,
    Lecture14Component,
    Lecture15Component,
    Lecture16Component,
    Lecture17Component,
    Lecture18Component,
    Lecture19Component,
    Lecture20Component,
    NextLevelButtonComponent,
    AllLevelsComponent,
    PrevLevelButtonComponent,
    IdeComponent,
    JavaBasicComponent,
    JavaCoreComponent,
    JavaCollectionsComponent,
    JavaMultithreadingComponent,
    JavaAdditionComponent,
    JavaEnterpriseComponent,
    JavaSpringComponent,
    Level1Component,
    FooterComponent,
    ArrowComponent,
    ArrowLeftRightComponent,
    HowItWorksComponent,
    Lecture21Component,
    // CareersComponent,
    // Job14Component,
    // Job17Component,
    // ApplyComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    // MatSnackBarModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

}
