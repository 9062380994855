import {Component, Output, Inject, PLATFORM_ID, OnInit} from '@angular/core';
// import { isPlatformBrowser} from '@angular/common';
import {UserService} from './service/user.service';
import {Router} from '@angular/router';
import {User} from './models/user';
import {Role} from './models/role';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  currentUser: User;
  roles: Array<Role>;
  title = 'JavaLx';

  constructor(private userService: UserService,
              private router: Router, private titleService: Title, private metaService: Meta
  ) {
    // method will check that user is logged in and user roles for activating the guards
    // this.userService.autoLogin();
    // Call it observable because it can be changed from other pages as login
    this.userService.currentUser.subscribe(data => {
        // console.log(data); // debug
        this.currentUser = data;

        if (this.currentUser !== null) {


          // https://www.concretepage.com/questions/578
          this.roles = data['authorities'];
          // trying to find user with admin role in 'authority' JSON response
          const kk = this.roles.find(ob => ob['authority'] === 'ROLE_ADMIN');

          // checking admin roles to hide some url visibility if user not admin

        }
      }
    );

  }

  // ngOnInit() {
  //   if(isPlatformBrowser(this.platformId)) {
  //     this.store.dispatch(new AuthActions.AutoLogin);
  //   }
  // }

  logOut() {
    this.userService.logOut().subscribe(data => {
      this.router.navigate(['/login']);
    });
  }
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    // this.metaService.addTags([
    //   {name: 'keywords', content: 'Java, JavaLx, LX, Java Lx, Learn, Java Education, Courses, Java Education,' +
    //       'Java Development, Spring Framework, Learn Java, Learn Java Simple, Learn Java Fast, Learn Spring, ' +
    //       'JavaEE'},
    //   {name: 'description', content: 'JavaLx is the practice course for future or current Java developers. ' +
    //       'We believe that anyone can become a programmer and do our best to make it simple and easy as possible! ' +
    //       'JavaLx on release date will provide over 1000 practical tasks because we believe that only by practice you can became the real developer.'},
    //   {name: 'robots', content: 'index, follow'}
    // ]);

    // this.metaService.addTags([
    //   {name: 'keywords', content: 'Java, JavaLx, LX, Java Lx, Java Education, Java Courses, Java Education,' +
    //       'Java Development, Spring Framework, Learn Java, Learn Java Simple, Learn Java Fast, Learn Spring, ' +
    //       'JavaEE'},
    //   {name: 'description', content: 'JavaLx is the Java course which focused on practice skills. ' +
    //       'We believe that anyone can become a programmer and do our best to make it simple and easy as possible!'},
    //   {name: 'robots', content: 'index, follow, noarchive, snippet'}
    // ]);

    this.metaService.addTags([
      {name: 'keywords', content: 'Java, JavaLx, LX, Java Lx, Java Education, Java Courses, Java Education,' +
          'Java Development, Spring Framework, Learn Java, Learn Java Simple, Learn Java Fast, Learn Spring, ' +
          'JavaEE'},
      {name: 'description', content: 'JavaLx is the practical Java course. ' +
          'The purpose of JavaLx is provide the best education in the Java development. ' +
          'We believe that anyone can become a programmer and do our best to make it simple and easy as possible!'},
      {name: 'robots', content: 'index, follow, archive, snippet'}
    ]);
  }


}
