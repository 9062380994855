import {Injectable, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from '../models/user';
import {Role} from '../models/role';

const API_URL = 'http://localhost:8888/api/user/service/';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public currentUser: Observable<User>;
   currentUserSubject: BehaviorSubject<User>;
   userAdmin: BehaviorSubject<User>;
  roles: Array<Role>;
  private userList: Array<User>;
  userChanged = new Subject<Array<User>>();

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser'))); // get current user from JSON
    this.currentUser = this.currentUserSubject.asObservable();
   }

   public get currentUserValue(): User {
    return this.currentUserSubject.value;
   }

     // set currentUser value to cookies
   login(user: User): Observable<any> {
     const headers = new HttpHeaders(
       user ? {
         authorization: 'Basic ' + btoa(user.username + ':' + user.password) // authorization
       } : {}
     );

     return this.http.get<any>(API_URL + 'login', {headers}).pipe(
       map(response => {
         console.log(response);
         if (response) {
           localStorage.setItem('currentUser', JSON.stringify(response));
           this.currentUserSubject.next(response);
         }
         return response;
       })
     );
   }

  // // get logged in user if refreshed page
  // autoLogin() {
  //   const userData: {
  //     userId: number;
  //     username: string;
  //     password: string;
  //     firstName: string;
  //     lastName: string;
  //     email: string;
  //     phone: string;
  //     enabled: boolean;
  //     role: Role;
  //
  //     // temp for register user
  //     confirmPassword: string;
  //   } = JSON.parse(localStorage.getItem('currentUser'));
  //   // if user isn't active return nothing => redirect to home page
  //   if (!userData) {
  //     return;
  //   }
  //
  //   // console.log(userData); // debug
  //   // getting user authorities
  //   this.roles = userData['authorities'];
  //   // from these authorities trying to find ROLE_ADMIN
  //   // if role doesn't exist than value will be undefined
  //   const role = this.roles.find(ob => ob['authority'] === 'ROLE_ADMIN');
  //
  //   // if we found admin role
  //   if (role !== undefined) {
  //     // passing value for the admin guard
  //     this.userAdmin = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
  //     this.userAdmin.next(userData);
  //   }
  //
  //
  //  // if user has valid token == user is logged in
  //   if (userData !== null) {
  //     // make current active user
  //     this.currentUserSubject.next(userData);
  //   }
  // }

   logOut(): Observable<any> {
    return this.http.post(API_URL + 'logout', {}).pipe(
      map(response => {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
      })
    );
  }


  // send post request
   register(user: User): Observable<any> {
     return this.http.post(API_URL + 'registration', JSON.stringify(user),
     {headers: {'Content-Type': 'application/json; charset=UTF-8'}});
   }

   showUserDetail(username: string): Observable<any> {
    return  this.http.get(API_URL + username, {headers: {'Content-Type': 'application/json; charset=UTF-8'}});
  }

}
