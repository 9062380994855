import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {UserService} from '../../service/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../models/user';
import {TaskService} from '../../service/task.service';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.css']
})
export class UserAccountComponent implements OnInit {

  currentUser: User;
  subscription: Subscription;

  constructor(private userService: UserService, private router: Router,
              private taskService: TaskService, private route: ActivatedRoute) {

  }

  ngOnInit() {

    this.subscription = this.userService.currentUser.subscribe(data => {
      this.currentUser = data;
      // localStorage.setItem('currentUser', JSON.stringify(response));
    });
    console.log(this.currentUser);
    console.log(this.currentUser.id);
  }

  nextLevel() {
    this.subscription = this.taskService.nextLevel(this.currentUser.id, 1, 1, 1).subscribe(data => {
      console.log(data);
      this.router.navigate(['/next/level1/sublevel1']);
    });
  }

}
